import { default as _91uuid_93YezMlm0aTYMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/[uuid].vue?macro=true";
import { default as otp2XpCO5T5IWMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/otp.vue?macro=true";
import { default as requestgDszWzQN2tMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/request.vue?macro=true";
import { default as _91uuid_93dAGoT4n1HoMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/invite/[uuid].vue?macro=true";
import { default as indexgKGvSFXFvbMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93GrdtKoNLkCMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as changeemailr5p69TCLDMMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/changeemail.vue?macro=true";
import { default as changepasswordUrNlqfA7f3Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/changepassword.vue?macro=true";
import { default as editprofileILRSkhfYx7Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/editprofile.vue?macro=true";
import { default as indexwTzaK5FmBvMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/index.vue?macro=true";
import { default as accountPKJg6pFSGKMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account.vue?macro=true";
import { default as addtn9As77k4rMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/methods/add.vue?macro=true";
import { default as indexjN49zYJbO1Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/methods/index.vue?macro=true";
import { default as add60UkvkggrtMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue?macro=true";
import { default as removebNtwf7y8e0Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue?macro=true";
import { default as addsubscriptionarGVBcIYVyMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue?macro=true";
import { default as indexWEX8T9PiidMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue?macro=true";
import { default as indexKyueUl4CtKMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/index.vue?macro=true";
import { default as index8iU5xMlDAQMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/index.vue?macro=true";
import { default as _91editId_93tZ8h6Yf7fPMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/[accountType]/[editId].vue?macro=true";
import { default as newU55PU8I8wSMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/[accountType]/new.vue?macro=true";
import { default as importy2rijQKAXpMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue?macro=true";
import { default as indexnxU6vmmXYBMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue?macro=true";
import { default as editfa84RxnVULMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/[dimensionId]/edit.vue?macro=true";
import { default as indexh6n9Ehyg3iMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/index.vue?macro=true";
import { default as newotzRvIOjXuMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/new.vue?macro=true";
import { default as duplicate4N97W27BYAMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/duplicate.vue?macro=true";
import { default as edit0yM3cdbj0cMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/edit.vue?macro=true";
import { default as index6mGc8EEenaMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/index.vue?macro=true";
import { default as index6bBCWrrxHlMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue?macro=true";
import { default as newmPrHt6ED2AMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/new.vue?macro=true";
import { default as editobi2avq9U3Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/[customerId]/edit.vue?macro=true";
import { default as indexntNWH6EpyxMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/index.vue?macro=true";
import { default as newtQV9PPwV2UMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/new.vue?macro=true";
import { default as indexS7OtQJQ8yUMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue?macro=true";
import { default as inventoryGLvMyKh5uUMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue?macro=true";
import { default as newHw6fWcuGw9Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue?macro=true";
import { default as edit7JCvkeGrGgMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/[vendorId]/edit.vue?macro=true";
import { default as indexZhnxnXKVNzMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/index.vue?macro=true";
import { default as newK7KOf5S0kAMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/new.vue?macro=true";
import { default as yeartoyearWX3zoQQoerMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue?macro=true";
import { default as _91receiptId_93sAM7WOgGwEMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue?macro=true";
import { default as indexVSLXFeKRlcMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue?macro=true";
import { default as newZYXNfeJ4HdMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/new.vue?macro=true";
import { default as editr8dRs2umA4Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId]/edit.vue?macro=true";
import { default as index6wLfBhRbIfMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId]/index.vue?macro=true";
import { default as indexSQqIZeStjLMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue?macro=true";
import { default as new42xZweCtALMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/new.vue?macro=true";
import { default as indexMntkDkZRPkMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as edit0VEjCb4ar6Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/[categoryId]/edit.vue?macro=true";
import { default as indexA6zFXtIzmiMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/index.vue?macro=true";
import { default as newydDEayDoyEMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/new.vue?macro=true";
import { default as editV6DcZDKdGcMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId]/edit.vue?macro=true";
import { default as indexT2EnxbkVNdMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId]/index.vue?macro=true";
import { default as importIbkqJ1lpgxMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue?macro=true";
import { default as index2VRvyJHkxzMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue?macro=true";
import { default as newfcz2Rxpx65Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/new.vue?macro=true";
import { default as editpwj8eDddRCMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId]/edit.vue?macro=true";
import { default as indexm2OzOtzOLeMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId]/index.vue?macro=true";
import { default as indexVpIyaqcamMMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue?macro=true";
import { default as newmD0w0VxtguMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/new.vue?macro=true";
import { default as editcN74CBxK8FMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId]/edit.vue?macro=true";
import { default as indexBEGKXpIlTjMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId]/index.vue?macro=true";
import { default as indexAdaMviyzbPMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue?macro=true";
import { default as new4bzSF2JAZZMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/new.vue?macro=true";
import { default as _91transferId_93YIT8VCVyFRMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue?macro=true";
import { default as indexjNXGPU5syYMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue?macro=true";
import { default as newlfGqFxGJ34Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/new.vue?macro=true";
import { default as indexz2f6uznK2GMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue?macro=true";
import { default as creditNotes_45_91id_937Ux23l1qZpMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/creditNotes-[id].vue?macro=true";
import { default as debitNotes_45_91id_930OugYzLdVXMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/debitNotes-[id].vue?macro=true";
import { default as generalReceipts_45_91id_93XrEEiKFn1zMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/generalReceipts-[id].vue?macro=true";
import { default as inventoryStocktakes_45_91id_93mAPqieY3QmMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/inventoryStocktakes-[id].vue?macro=true";
import { default as inventoryTransfers_45_91id_93L7KKds4qrGMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/inventoryTransfers-[id].vue?macro=true";
import { default as manualJournals_45_91id_93wEMNQUak4xMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/manualJournals-[id].vue?macro=true";
import { default as order_45_91id_93VuLjI0E5RgMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue?macro=true";
import { default as barcode_45_91id_93HC12cGlc6GMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue?macro=true";
import { default as purchaseOrders_45_91id_93GADRBExpHJMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchaseOrders-[id].vue?macro=true";
import { default as purchasingInvoices_45_91id_93OFyvnLDiF7Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchasingInvoices-[id].vue?macro=true";
import { default as purchasingReceipts_45_91id_93isvpqnjvzJMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchasingReceipts-[id].vue?macro=true";
import { default as accountstatementNvrfDdwO02Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue?macro=true";
import { default as balancesheetiqmkSJRfhXMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue?macro=true";
import { default as currentinventory5h30VIELa0Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue?macro=true";
import { default as customerstatementteCQ9tIbdPMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue?macro=true";
import { default as generalreceiptshzl6NeSJWBMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/generalreceipts.vue?macro=true";
import { default as incomestatement4CWhsRrQkOMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue?macro=true";
import { default as inventoryledger7B7DPOhNUDMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventoryledger.vue?macro=true";
import { default as purchasinginvoicesNHqr3d6n8JMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue?macro=true";
import { default as purchasinginvoicesagingAC1p2tdvPcMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoicesaging.vue?macro=true";
import { default as purchasingreceiptsV2kOaFyk3WMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasingreceipts.vue?macro=true";
import { default as salesinvoicesfLp2EVUoU4Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue?macro=true";
import { default as salesinvoicesagingtwxD3JEtYmMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoicesaging.vue?macro=true";
import { default as salesreceiptsmDIbeXua2hMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesreceipts.vue?macro=true";
import { default as salestotalbyposttkAHoNybpMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbypos.vue?macro=true";
import { default as salestotalbyproducts6atQSieIbRMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyproducts.vue?macro=true";
import { default as salestotalbystoreGfGowneEeHMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbystore.vue?macro=true";
import { default as salestotalbyteammemberFfak5N4IHmMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyteammember.vue?macro=true";
import { default as saudivatreport3tDNT7TxJPMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue?macro=true";
import { default as transactionshistoryix0NiGerWyMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue?macro=true";
import { default as trialbalancerz12YlmeLDMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue?macro=true";
import { default as vendorstatementIbYKEzQwOGMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue?macro=true";
import { default as salesInvoices_45_91id_93rVS16lgnYKMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesInvoices-[id].vue?macro=true";
import { default as salesQuotations_45_91id_93BCcCIIaWOUMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesQuotations-[id].vue?macro=true";
import { default as salesReceipts_45_91id_93gxhjfjwg3aMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesReceipts-[id].vue?macro=true";
import { default as printHu7oauknzrMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print.vue?macro=true";
import { default as _91id_93lTE06dmbrHMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/printtemplates-[documentType]/[id].vue?macro=true";
import { default as newxZezIhUCYTMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/printtemplates-[documentType]/new.vue?macro=true";
import { default as _91debitNoteId_93FCEMrkl6JLMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue?macro=true";
import { default as _91invoiceId_93iEmq0OtTDhMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexO4gsKN0zmsMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue?macro=true";
import { default as newG7vhWAVO3JMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/new.vue?macro=true";
import { default as _91invoiceId_93zgMCXf3f4rMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue?macro=true";
import { default as indexceFO4VYPS9Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue?macro=true";
import { default as newBvHjZaNrEoMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue?macro=true";
import { default as editSwvz8SUifiMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/[policyId]/edit.vue?macro=true";
import { default as indexRoBkcGgDVcMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/[policyId]/index.vue?macro=true";
import { default as index7v8TuX2MmCMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/index.vue?macro=true";
import { default as newMKViVvRZiaMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/new.vue?macro=true";
import { default as duplicateBSODaBFKsxMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/[purchaseorderId]/duplicate.vue?macro=true";
import { default as indexekw9V8l4s1Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/[purchaseorderId]/index.vue?macro=true";
import { default as indexxYPY7VtJykMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/index.vue?macro=true";
import { default as newaQ5VET6u03Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/new.vue?macro=true";
import { default as _91receiptId_93CYnHliTPF0Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue?macro=true";
import { default as indexe5ciJtQeP6Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue?macro=true";
import { default as newvbk6FVh3P1Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/new.vue?macro=true";
import { default as edit9t4H8SjhCwMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId]/edit.vue?macro=true";
import { default as indexNl3Q355mTyMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId]/index.vue?macro=true";
import { default as importMPhyNOI2lXMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue?macro=true";
import { default as indexMmjvYV0OmYMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue?macro=true";
import { default as newL53tBobcDqMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/new.vue?macro=true";
import { default as accountstatementEOegXH0jiLMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue?macro=true";
import { default as balancesheet8QsnryMdocMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue?macro=true";
import { default as currentinventorysFV9Ew85M7Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue?macro=true";
import { default as customerstatementJjWg0wl9kQMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue?macro=true";
import { default as generalreceiptsj2B8DITXYlMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/generalreceipts.vue?macro=true";
import { default as incomestatementRJgrDpKkNqMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue?macro=true";
import { default as inventoryledgerhUjMLkLzRoMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/inventoryledger.vue?macro=true";
import { default as purchasinginvoicesnB1akMGJH5Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue?macro=true";
import { default as purchasinginvoicesagingWs42QRfmGFMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoicesaging.vue?macro=true";
import { default as purchasingreceiptsiGvE3f7nlZMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasingreceipts.vue?macro=true";
import { default as salesinvoicesBXMxlpWknkMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue?macro=true";
import { default as salesinvoicesaginggryq3CDO4xMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoicesaging.vue?macro=true";
import { default as salesreceiptsBwjZE842dVMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesreceipts.vue?macro=true";
import { default as salestotalbyposhmA1GiYSr9Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbypos.vue?macro=true";
import { default as salestotalbyproductsKEYIeNeZ6VMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyproducts.vue?macro=true";
import { default as salestotalbystoreBw4CPWH60JMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbystore.vue?macro=true";
import { default as salestotalbyteammembere9yig0Ft44Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyteammember.vue?macro=true";
import { default as indexVmiVoDoBF1Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport/index.vue?macro=true";
import { default as new729vt63VDdMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport/new.vue?macro=true";
import { default as transactionshistoryfC7CO839WdMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue?macro=true";
import { default as trialbalanceK6os8xkxWPMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue?macro=true";
import { default as vendorstatementQ0kQ652XpoMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue?macro=true";
import { default as _91creditNoteId_93dHyFG9xVwvMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue?macro=true";
import { default as _91invoiceId_93nGxlTqUOfHMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexIoJ9KCeoY9Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue?macro=true";
import { default as newAyRh4VS1drMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/new.vue?macro=true";
import { default as editX5ztbhkSgeMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId]/edit.vue?macro=true";
import { default as indexoaewfDjOwLMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId]/index.vue?macro=true";
import { default as importstWCsGvgWqMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue?macro=true";
import { default as indexR2ckOFPcTZMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue?macro=true";
import { default as newDeRLfJSgQQMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/new.vue?macro=true";
import { default as _91invoiceId_933lGRySF0d7Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue?macro=true";
import { default as index4zxEe9qiXTMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue?macro=true";
import { default as newidFZ3c1B8IMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue?macro=true";
import { default as editDvpnUkQ6lnMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/[policyId]/edit.vue?macro=true";
import { default as indexEY2ASOreFTMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/[policyId]/index.vue?macro=true";
import { default as indext5NJCBzCezMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/index.vue?macro=true";
import { default as new0UjGjGiRzFMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/new.vue?macro=true";
import { default as _91receiptId_93SCaGkd94TNMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue?macro=true";
import { default as indexsS6kVwot5UMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue?macro=true";
import { default as newnvsnRv5nKSMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/new.vue?macro=true";
import { default as duplicate4MdOafySyXMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/[salesquotationId]/duplicate.vue?macro=true";
import { default as indexsSrP7XHS99Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/[salesquotationId]/index.vue?macro=true";
import { default as indexrLiUHhEKPzMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/index.vue?macro=true";
import { default as new0fybyduAhgMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/new.vue?macro=true";
import { default as attachmentslibrary8baOdrNRcLMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/attachmentslibrary.vue?macro=true";
import { default as editWxDLrJfdUsMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/[customfieldsId]/edit.vue?macro=true";
import { default as indexBKwABpd2QtMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/index.vue?macro=true";
import { default as newlQROdiCmjlMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/new.vue?macro=true";
import { default as customtemplatesv7meiw8bLSMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customtemplates.vue?macro=true";
import { default as detailsSXgAgtGs97Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/details.vue?macro=true";
import { default as indexqYSK29eSELMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/integrations/index.vue?macro=true";
import { default as zatcaiDy2uJdv1hMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/integrations/zatca.vue?macro=true";
import { default as editrgAtFUrGXEMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/[memberId]/edit.vue?macro=true";
import { default as indexSjXjV70WM8Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/index.vue?macro=true";
import { default as newO5ruRq5NHHMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/new.vue?macro=true";
import { default as edit7d3VmeCFgHMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/[posId]/edit.vue?macro=true";
import { default as indexf9F5Pqv7d8Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/index.vue?macro=true";
import { default as newQ2faVTKkNbMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/new.vue?macro=true";
import { default as settingszpAc5bIX2uMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/settings.vue?macro=true";
import { default as editkGjSVLXuBbMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/[receiptpaymentmethodId]/edit.vue?macro=true";
import { default as indexXGWOunGknNMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/index.vue?macro=true";
import { default as newVCoYR85ZKBMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/new.vue?macro=true";
import { default as editcNgiuq8eFZMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/[roleId]/edit.vue?macro=true";
import { default as indexXNbSzjgra2Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/index.vue?macro=true";
import { default as newdIkfVVd3YpMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/new.vue?macro=true";
import { default as editcpFNKzrGR4Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/[taxId]/edit.vue?macro=true";
import { default as indexdYCRSRByvXMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/index.vue?macro=true";
import { default as newrfgD8F7vBZMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/new.vue?macro=true";
import { default as transactioncounterseCQg355fK9Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactioncounters.vue?macro=true";
import { default as transactiondefaultsAiryczRl2rMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactiondefaults.vue?macro=true";
import { default as edit9SgwPUN83PMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/[lockedperiodId]/edit.vue?macro=true";
import { default as indexCFyt7CDQCrMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/index.vue?macro=true";
import { default as newEJF7TBl4qKMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/new.vue?macro=true";
import { default as settingsOBTajtkih3Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings.vue?macro=true";
import { default as _91orgId_93EuayuucIVjMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId].vue?macro=true";
import { default as createP022ArReHTMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/create.vue?macro=true";
import { default as dashboardNF6Es4PYD5Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/dashboard.vue?macro=true";
import { default as index74dBjonwN2Meta } from "/home/runner/work/mezan/mezan/apps/main/pages/index.vue?macro=true";
import { default as registerFxJ6fdUY8vMeta } from "/home/runner/work/mezan/mezan/apps/main/pages/register.vue?macro=true";
export default [
  {
    name: "auth-email-verify-uuid",
    path: "/auth/email/verify/:uuid()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-otp",
    path: "/auth/email/verify/otp",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-request",
    path: "/auth/email/verify/request",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/email/verify/request.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-submit-uuid",
    path: "/auth/password/reset/submit/:uuid()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: dashboardNF6Es4PYD5Meta?.name,
    path: "/dashboard",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: accountPKJg6pFSGKMeta?.name,
    path: "account",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-account-changeemail",
    path: "changeemail",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/changeemail.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-changepassword",
    path: "changepassword",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/changepassword.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-editprofile",
    path: "editprofile",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/editprofile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-billing-methods-add",
    path: "billing/methods/add",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/methods/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-methods",
    path: "billing/methods",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/methods/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addons-add",
    path: "billing/subscriptions/:orgId()/addons/add",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addons-remove",
    path: "billing/subscriptions/:orgId()/addons/remove",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addsubscription",
    path: "billing/subscriptions/:orgId()/addsubscription",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId",
    path: "billing/subscriptions/:orgId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions",
    path: "billing/subscriptions",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/billing/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91orgId_93EuayuucIVjMeta?.name,
    path: "organizations/:orgId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId].vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-organizations-orgId-accounting-accounts-accountType-editId",
    path: "accounting/accounts/:accountType()/:editId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/[accountType]/[editId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-accounts-accountType-new",
    path: "accounting/accounts/:accountType()/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/[accountType]/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-accounts-import",
    path: "accounting/accounts/import",
    meta: importy2rijQKAXpMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-accounts",
    path: "accounting/accounts",
    meta: indexnxU6vmmXYBMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-dimensions-dimensionId-edit",
    path: "accounting/dimensions/:dimensionId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/[dimensionId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-dimensions",
    path: "accounting/dimensions",
    meta: indexh6n9Ehyg3iMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-dimensions-new",
    path: "accounting/dimensions/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal-journalId-duplicate",
    path: "accounting/journal/:journalId()/duplicate",
    meta: duplicate4N97W27BYAMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal-journalId-edit",
    path: "accounting/journal/:journalId()/edit",
    meta: edit0yM3cdbj0cMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal-journalId",
    path: "accounting/journal/:journalId()",
    meta: index6mGc8EEenaMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal",
    path: "accounting/journal",
    meta: index6bBCWrrxHlMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal-new",
    path: "accounting/journal/new",
    meta: newmPrHt6ED2AMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-customers-customerId-edit",
    path: "accounting/opening/customers/:customerId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/[customerId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-customers",
    path: "accounting/opening/customers",
    meta: indexntNWH6EpyxMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-customers-new",
    path: "accounting/opening/customers/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening",
    path: "accounting/opening",
    meta: indexS7OtQJQ8yUMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-inventory",
    path: "accounting/opening/inventory",
    meta: inventoryGLvMyKh5uUMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-new",
    path: "accounting/opening/new",
    meta: newHw6fWcuGw9Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-vendors-vendorId-edit",
    path: "accounting/opening/vendors/:vendorId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/[vendorId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-vendors",
    path: "accounting/opening/vendors",
    meta: indexZhnxnXKVNzMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-vendors-new",
    path: "accounting/opening/vendors/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-yeartoyear",
    path: "accounting/opening/yeartoyear",
    meta: yeartoyearWX3zoQQoerMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-receipts-receiptId",
    path: "accounting/receipts/:receiptId()",
    meta: _91receiptId_93sAM7WOgGwEMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-receipts",
    path: "accounting/receipts",
    meta: indexVSLXFeKRlcMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-receipts-new",
    path: "accounting/receipts/new",
    meta: newZYXNfeJ4HdMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets-assetId-edit",
    path: "fixedassets/assets/:assetId()/edit",
    meta: editr8dRs2umA4Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets-assetId",
    path: "fixedassets/assets/:assetId()",
    meta: index6wLfBhRbIfMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets",
    path: "fixedassets/assets",
    meta: indexSQqIZeStjLMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets-new",
    path: "fixedassets/assets/new",
    meta: new42xZweCtALMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId",
    path: "",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-categories-categoryId-edit",
    path: "inventory/categories/:categoryId()/edit",
    meta: edit0VEjCb4ar6Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/[categoryId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-categories",
    path: "inventory/categories",
    meta: indexA6zFXtIzmiMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-categories-new",
    path: "inventory/categories/new",
    meta: newydDEayDoyEMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-productId-edit",
    path: "inventory/products/:productId()/edit",
    meta: editV6DcZDKdGcMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-productId",
    path: "inventory/products/:productId()",
    meta: indexT2EnxbkVNdMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-import",
    path: "inventory/products/import",
    meta: importIbkqJ1lpgxMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products",
    path: "inventory/products",
    meta: index2VRvyJHkxzMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-new",
    path: "inventory/products/new",
    meta: newfcz2Rxpx65Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes-stocktakeId-edit",
    path: "inventory/stocktakes/:stocktakeId()/edit",
    meta: editpwj8eDddRCMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes-stocktakeId",
    path: "inventory/stocktakes/:stocktakeId()",
    meta: indexm2OzOtzOLeMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes",
    path: "inventory/stocktakes",
    meta: indexVpIyaqcamMMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes-new",
    path: "inventory/stocktakes/new",
    meta: newmD0w0VxtguMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores-storeId-edit",
    path: "inventory/stores/:storeId()/edit",
    meta: editcN74CBxK8FMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores-storeId",
    path: "inventory/stores/:storeId()",
    meta: indexBEGKXpIlTjMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores",
    path: "inventory/stores",
    meta: indexAdaMviyzbPMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores-new",
    path: "inventory/stores/new",
    meta: new4bzSF2JAZZMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-transfers-transferId",
    path: "inventory/transfers/:transferId()",
    meta: _91transferId_93YIT8VCVyFRMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-transfers",
    path: "inventory/transfers",
    meta: indexjNXGPU5syYMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-transfers-new",
    path: "inventory/transfers/new",
    meta: newlfGqFxGJ34Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-onboarding",
    path: "onboarding",
    meta: indexz2f6uznK2GMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print",
    path: "print",
    meta: printHu7oauknzrMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-organizations-orgId-print-creditNotes-id",
    path: "creditNotes-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/creditNotes-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-debitNotes-id",
    path: "debitNotes-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/debitNotes-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-generalReceipts-id",
    path: "generalReceipts-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/generalReceipts-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-inventoryStocktakes-id",
    path: "inventoryStocktakes-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/inventoryStocktakes-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-inventoryTransfers-id",
    path: "inventoryTransfers-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/inventoryTransfers-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-manualJournals-id",
    path: "manualJournals-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/manualJournals-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-order-id",
    path: "order-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-products-barcode-id",
    path: "products/barcode-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchaseOrders-id",
    path: "purchaseOrders-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchaseOrders-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasingInvoices-id",
    path: "purchasingInvoices-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchasingInvoices-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasingReceipts-id",
    path: "purchasingReceipts-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/purchasingReceipts-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-accountstatement",
    path: "reports/accountstatement",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-balancesheet",
    path: "reports/balancesheet",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-currentinventory",
    path: "reports/currentinventory",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-customerstatement",
    path: "reports/customerstatement",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-generalreceipts",
    path: "reports/generalreceipts",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/generalreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-incomestatement",
    path: "reports/incomestatement",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-inventoryledger",
    path: "reports/inventoryledger",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventoryledger.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasinginvoices",
    path: "reports/purchasinginvoices",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasinginvoicesaging",
    path: "reports/purchasinginvoicesaging",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasingreceipts",
    path: "reports/purchasingreceipts",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasingreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesinvoices",
    path: "reports/salesinvoices",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesinvoicesaging",
    path: "reports/salesinvoicesaging",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesreceipts",
    path: "reports/salesreceipts",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbypos",
    path: "reports/salestotalbypos",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbypos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbyproducts",
    path: "reports/salestotalbyproducts",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyproducts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbystore",
    path: "reports/salestotalbystore",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbystore.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbyteammember",
    path: "reports/salestotalbyteammember",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyteammember.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-saudivatreport",
    path: "reports/saudivatreport",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-transactionshistory",
    path: "reports/transactionshistory",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-trialbalance",
    path: "reports/trialbalance",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-vendorstatement",
    path: "reports/vendorstatement",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-salesInvoices-id",
    path: "salesInvoices-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesInvoices-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-salesQuotations-id",
    path: "salesQuotations-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesQuotations-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-salesReceipts-id",
    path: "salesReceipts-:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/print/salesReceipts-[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-organizations-orgId-printtemplates-documentType-id",
    path: "printtemplates-:documentType()/:id()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/printtemplates-[documentType]/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-printtemplates-documentType-new",
    path: "printtemplates-:documentType()/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/printtemplates-[documentType]/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes-debitNoteId",
    path: "purchasing/debitnotes/:debitNoteId()",
    meta: _91debitNoteId_93FCEMrkl6JLMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes-frominvoice-invoiceId",
    path: "purchasing/debitnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93iEmq0OtTDhMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes",
    path: "purchasing/debitnotes",
    meta: indexO4gsKN0zmsMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes-new",
    path: "purchasing/debitnotes/new",
    meta: newG7vhWAVO3JMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices-invoiceId",
    path: "purchasing/invoices/:invoiceId()",
    meta: _91invoiceId_93zgMCXf3f4rMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices",
    path: "purchasing/invoices",
    meta: indexceFO4VYPS9Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices-new",
    path: "purchasing/invoices/new",
    meta: newBvHjZaNrEoMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-pricingpolicies-policyId-edit",
    path: "purchasing/pricingpolicies/:policyId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/[policyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-pricingpolicies-policyId",
    path: "purchasing/pricingpolicies/:policyId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/[policyId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-pricingpolicies",
    path: "purchasing/pricingpolicies",
    meta: index7v8TuX2MmCMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-pricingpolicies-new",
    path: "purchasing/pricingpolicies/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/pricingpolicies/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-purchaseorders-purchaseorderId-duplicate",
    path: "purchasing/purchaseorders/:purchaseorderId()/duplicate",
    meta: duplicateBSODaBFKsxMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/[purchaseorderId]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-purchaseorders-purchaseorderId",
    path: "purchasing/purchaseorders/:purchaseorderId()",
    meta: indexekw9V8l4s1Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/[purchaseorderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-purchaseorders",
    path: "purchasing/purchaseorders",
    meta: indexxYPY7VtJykMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-purchaseorders-new",
    path: "purchasing/purchaseorders/new",
    meta: newaQ5VET6u03Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-receipts-receiptId",
    path: "purchasing/receipts/:receiptId()",
    meta: _91receiptId_93CYnHliTPF0Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-receipts",
    path: "purchasing/receipts",
    meta: indexe5ciJtQeP6Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-receipts-new",
    path: "purchasing/receipts/new",
    meta: newvbk6FVh3P1Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-vendorId-edit",
    path: "purchasing/vendors/:vendorId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-vendorId",
    path: "purchasing/vendors/:vendorId()",
    meta: indexNl3Q355mTyMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-import",
    path: "purchasing/vendors/import",
    meta: importMPhyNOI2lXMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors",
    path: "purchasing/vendors",
    meta: indexMmjvYV0OmYMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-new",
    path: "purchasing/vendors/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-accountstatement",
    path: "reports/accountstatement",
    meta: accountstatementEOegXH0jiLMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-balancesheet",
    path: "reports/balancesheet",
    meta: balancesheet8QsnryMdocMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-currentinventory",
    path: "reports/currentinventory",
    meta: currentinventorysFV9Ew85M7Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-customerstatement",
    path: "reports/customerstatement",
    meta: customerstatementJjWg0wl9kQMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-generalreceipts",
    path: "reports/generalreceipts",
    meta: generalreceiptsj2B8DITXYlMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/generalreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-incomestatement",
    path: "reports/incomestatement",
    meta: incomestatementRJgrDpKkNqMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-inventoryledger",
    path: "reports/inventoryledger",
    meta: inventoryledgerhUjMLkLzRoMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/inventoryledger.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasinginvoices",
    path: "reports/purchasinginvoices",
    meta: purchasinginvoicesnB1akMGJH5Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasinginvoicesaging",
    path: "reports/purchasinginvoicesaging",
    meta: purchasinginvoicesagingWs42QRfmGFMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasingreceipts",
    path: "reports/purchasingreceipts",
    meta: purchasingreceiptsiGvE3f7nlZMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasingreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesinvoices",
    path: "reports/salesinvoices",
    meta: salesinvoicesBXMxlpWknkMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesinvoicesaging",
    path: "reports/salesinvoicesaging",
    meta: salesinvoicesaginggryq3CDO4xMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesreceipts",
    path: "reports/salesreceipts",
    meta: salesreceiptsBwjZE842dVMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salesreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbypos",
    path: "reports/salestotalbypos",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbypos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbyproducts",
    path: "reports/salestotalbyproducts",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyproducts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbystore",
    path: "reports/salestotalbystore",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbystore.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbyteammember",
    path: "reports/salestotalbyteammember",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyteammember.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-saudivatreport",
    path: "reports/saudivatreport",
    meta: indexVmiVoDoBF1Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-saudivatreport-new",
    path: "reports/saudivatreport/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-transactionshistory",
    path: "reports/transactionshistory",
    meta: transactionshistoryfC7CO839WdMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-trialbalance",
    path: "reports/trialbalance",
    meta: trialbalanceK6os8xkxWPMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-vendorstatement",
    path: "reports/vendorstatement",
    meta: vendorstatementQ0kQ652XpoMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes-creditNoteId",
    path: "sales/creditnotes/:creditNoteId()",
    meta: _91creditNoteId_93dHyFG9xVwvMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes-frominvoice-invoiceId",
    path: "sales/creditnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93nGxlTqUOfHMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes",
    path: "sales/creditnotes",
    meta: indexIoJ9KCeoY9Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes-new",
    path: "sales/creditnotes/new",
    meta: newAyRh4VS1drMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-customerId-edit",
    path: "sales/customers/:customerId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-customerId",
    path: "sales/customers/:customerId()",
    meta: indexoaewfDjOwLMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-import",
    path: "sales/customers/import",
    meta: importstWCsGvgWqMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers",
    path: "sales/customers",
    meta: indexR2ckOFPcTZMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-new",
    path: "sales/customers/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices-invoiceId",
    path: "sales/invoices/:invoiceId()",
    meta: _91invoiceId_933lGRySF0d7Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices",
    path: "sales/invoices",
    meta: index4zxEe9qiXTMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices-new",
    path: "sales/invoices/new",
    meta: newidFZ3c1B8IMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-pricingpolicies-policyId-edit",
    path: "sales/pricingpolicies/:policyId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/[policyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-pricingpolicies-policyId",
    path: "sales/pricingpolicies/:policyId()",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/[policyId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-pricingpolicies",
    path: "sales/pricingpolicies",
    meta: indext5NJCBzCezMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-pricingpolicies-new",
    path: "sales/pricingpolicies/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/pricingpolicies/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-receipts-receiptId",
    path: "sales/receipts/:receiptId()",
    meta: _91receiptId_93SCaGkd94TNMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-receipts",
    path: "sales/receipts",
    meta: indexsS6kVwot5UMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-receipts-new",
    path: "sales/receipts/new",
    meta: newnvsnRv5nKSMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-salesquotations-salesquotationId-duplicate",
    path: "sales/salesquotations/:salesquotationId()/duplicate",
    meta: duplicate4MdOafySyXMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/[salesquotationId]/duplicate.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-salesquotations-salesquotationId",
    path: "sales/salesquotations/:salesquotationId()",
    meta: indexsSrP7XHS99Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/[salesquotationId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-salesquotations",
    path: "sales/salesquotations",
    meta: indexrLiUHhEKPzMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-salesquotations-new",
    path: "sales/salesquotations/new",
    meta: new0fybyduAhgMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings",
    path: "settings",
    meta: settingsOBTajtkih3Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-organizations-orgId-settings-attachmentslibrary",
    path: "attachmentslibrary",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/attachmentslibrary.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-customfields-customfieldsId-edit",
    path: "customfields/:customfieldsId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/[customfieldsId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-customfields",
    path: "customfields",
    meta: indexBKwABpd2QtMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-customfields-new",
    path: "customfields/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customfields/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-customtemplates",
    path: "customtemplates",
    meta: customtemplatesv7meiw8bLSMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/customtemplates.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-details",
    path: "details",
    meta: detailsSXgAgtGs97Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-integrations",
    path: "integrations",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-integrations-zatca",
    path: "integrations/zatca",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/integrations/zatca.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-members-memberId-edit",
    path: "members/:memberId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/[memberId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-members",
    path: "members",
    meta: indexSjXjV70WM8Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-members-new",
    path: "members/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/members/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-pos-posId-edit",
    path: "pos/:posId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/[posId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-pos",
    path: "pos",
    meta: indexf9F5Pqv7d8Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-pos-new",
    path: "pos/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-pos-settings",
    path: "pos/settings",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/pos/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-receiptpaymentmethod-receiptpaymentmethodId-edit",
    path: "receiptpaymentmethod/:receiptpaymentmethodId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/[receiptpaymentmethodId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-receiptpaymentmethod",
    path: "receiptpaymentmethod",
    meta: indexXGWOunGknNMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-receiptpaymentmethod-new",
    path: "receiptpaymentmethod/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/receiptpaymentmethod/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-roles-roleId-edit",
    path: "roles/:roleId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/[roleId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-roles",
    path: "roles",
    meta: indexXNbSzjgra2Meta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-roles-new",
    path: "roles/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-tax-taxId-edit",
    path: "tax/:taxId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/[taxId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-tax",
    path: "tax",
    meta: indexdYCRSRByvXMeta || {},
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-tax-new",
    path: "tax/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/tax/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-transactioncounters",
    path: "transactioncounters",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactioncounters.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-transactiondefaults",
    path: "transactiondefaults",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactiondefaults.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-transactionlocks-lockedperiodId-edit",
    path: "transactionlocks/:lockedperiodId()/edit",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/[lockedperiodId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-transactionlocks",
    path: "transactionlocks",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-settings-transactionlocks-new",
    path: "transactionlocks/new",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/[orgId]/settings/transactionlocks/new.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "dashboard-organizations-create",
    path: "organizations/create",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/dashboard/organizations/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/mezan/mezan/apps/main/pages/register.vue").then(m => m.default || m)
  }
]