import validate from "/home/runner/work/mezan/mezan/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/mezan/mezan/apps/main/middleware/auth.global.ts";
import authorize_45global from "/home/runner/work/mezan/mezan/apps/main/middleware/authorize.global.ts";
import manifest_45route_45rule from "/home/runner/work/mezan/mezan/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  authorize_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}